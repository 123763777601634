body {
  font-family: "Montserrat", sans-serif;
  max-width: 100%;
  color: rgb(0, 0, 0);
}

h1,
h2,
h3 {
  margin: 0.5rem;
}
h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.5rem;
}
h3 {
  font-size: 1.2rem;
}
a {
  text-decoration: none;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: baseline;
  text-align: center;
}

.col-1 {
  flex: 1;
}

.col-2 {
  flex: 2;
}

.block {
  border-width: 10px;
  border-color: #7788994a;
  border-style: solid;
  background: rgb(255 255 255 / 0%);
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
}

.row.center {
  align-items: center;
  flex-direction: column;
  align-content: center;
}

img.small {
  max-height: 10rem;
  margin: 1rem;
}

.title {
  color: rgb(0, 0, 0);
}

button {
  font-size: 0.8rem;
  padding: 0.2rem;
  margin: 0.1rem;
  border-radius: 0.5rem;
  border: 0.1rem #404040 solid;
  background-color: #eae9e9;
  width: 100%;
  cursor: pointer;
}

button.add {
  background-color: hsl(128, 56%, 54%);
  width: 2rem;
  font-size: 1rem;
}

button.remove {
  background-color: #ee6556;
  width: 2rem;
  font-size: 1rem;
}

button.badge {
  background-color: #f04040;
  border: none;
  color: white;
  width: 1.5rem;
}

.text-right {
  text-align: right;
}

.disclaimer {
  color: rgb(255 39 17 / 82%);
  margin: auto;
  text-align: center;
}
